import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { WebTrafficIndustrySummaryDataType } from '@/api/webTraffic';
import Flare, { Axis, FlareSeriesOptions, Tooltip } from '@/components/Flare';
import Bar from '@/components/Flare/marks/Bar';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { COLOR_GREEN_400 } from '@/styles/palette';

type Props = {
  data?: WebTrafficIndustrySummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error: SolAPIError | null;
};

const WebTrafficIndustriesChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'companies',
        name: t`Companies`,
        data: data.map((slice) => ({
          name: slice.sector.name,
          amount: slice.engagedCompanies,
          custom: {
            sectorId: slice.sector.id,
            companyPercent: slice.percentOfTotalCompanies,
            visits: slice.count,
            visitsPercent: slice.percentOfTotal,
          },
        })),
      },
    ];
  }, [data]);

  return (
    <Flare
      data={seriesData}
      description={t`Top industries by number of companies and visits`}
      colors={[COLOR_GREEN_400]}
      isLoading={isLoading}
      error={error}
      marginTop={5}
      marginBottom={5}
      marginLeft={0}
      marginRight={0}
      paddingLeft={5}
      paddingRight={5}
    >
      <Bar
        y="amount"
        position="dodge"
        minBarHeight={40}
        groupPadding={0.2}
        showDataLabels
        dataLabelFormat={(label) => (label.y === 0 ? '' : numberFormat(label.y))}
        skeletonLoaderOptions={{
          numSeries: audience?.id ? 3 : 2,
          numCategories: 3,
          sortDescending: true,
        }}
      />
      <Axis
        position="right"
        labelFormat={(item) => numberFormat(item.value)}
        gridlineWidth={0}
        baselineWidth={1}
        tickAmount={5}
      />
      <Axis
        position="bottom"
        categories={data?.map((d) => d.sector.name) || []}
        crosshair="rect"
        baselineWidth={0}
        labelFormat={(item) =>
          `<div style="text-align: right; overflow: hidden; text-overflow: ellipsis;">${item.value}</div>`
        }
        useHTML
      />
      <Tooltip
        titleFormat={(point) => point?.key}
        rowLabelFormat={(point) => `${point?.series?.name}<br/>${t`Visits`}`}
        rowSecondaryValueFormat={(point) =>
          `(${numberFormat(point?.custom?.companyPercent, { isPercent: true })})<br/>(${numberFormat(point?.custom?.visitsPercent, { isPercent: true })})`
        }
        rowValueFormat={(point) =>
          `${numberFormat(point?.y)}<br/>${numberFormat(point?.custom?.visits)}`
        }
        valueLink={(point) => {
          return ROUTES.webTrafficActivityWithParams({
            'sector.id': point?.custom?.sectorId,
            'metrics.visits': '[1,]',
            audienceIds: audience?.id,
          });
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficIndustriesChart;
