import gql from 'graphql-tag';
import { useMemo } from 'react';

import { useSolQuery } from '@/hooks/useSolQuery';

import { RecentDealVisitTrendsGQLResponse, RecentDealsGQLResponse } from './opportunityType';

export const useRecentDeals = () => {
  const { data, error, isLoading } = useSolQuery<RecentDealsGQLResponse>({
    query: gql`
      query Opportunities {
        opportunities {
          recentDeals {
            id
            name
            account {
              company {
                name
              }
              visits
              people
              vendors
            }
            openDate
            closeDate
            daysToClose
            amount
          }
        }
      }
    `,
  });

  return {
    recentDeals: data?.opportunities.recentDeals,
    isLoading,
    error,
  };
};

export const useRecentDealVisitTrends = (opportunityId?: string) => {
  const { data, error, isLoading } = useSolQuery<RecentDealVisitTrendsGQLResponse>({
    query:
      !!opportunityId &&
      gql`
        query RecentDealVisitTrends($opportunityId: String) {
          visualization {
            recentDealsTrends(opportunityId: $opportunityId) {
              opportunity {
                id
                name
                openDate
                closeDate
                amount
              }
              channelData {
                channel {
                  id
                  name
                  color
                }
                data {
                  sundayOfWeek
                  runningTotalVisits
                }
              }
            }
          }
        }
      `,
    variables: {
      opportunityId,
    },
  });

  useMemo(() => {
    const { recentDealsTrends } = data?.visualization ?? {};

    if (!recentDealsTrends) {
      return;
    }

    recentDealsTrends.channelData.sort((a, b) => {
      if (a.channel.name < b.channel.name) {
        return -1;
      }
      if (a.channel.name > b.channel.name) {
        return 1;
      }
      return 0;
    });

    return recentDealsTrends;
  }, [data]);

  return {
    recentDealVisitTrends: data?.visualization.recentDealsTrends,
    isLoading,
    error,
  };
};
